<template>
  <div>
    <b-modal ref="waitDialog" title="Please wait"
      header-bg-variant="info" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      hide-header-close no-close-on-backdrop no-close-on-esc
      content-class="shadow" ok-only ok-disabled>
      <span>Retrieving new report from server...</span>
    </b-modal>
    <b-modal ref="warningDialog" title="Report Error"
      header-bg-variant="warning" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" @ok="handleWarningOK" ok-only>
      <span>{{warningMsg}}</span>
    </b-modal>
    <b-modal ref="createDialog" :title="title" size="lg" scrollable
      header-bg-variant="secondary" header-text-variant="light"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow"  @ok="handleCreateOK"
      :ok-title="okTitle"
      no-close-on-esc no-close-on-backdrop hide-header-close>
      <b-container fluid>
        <b-row>
          <b-col>
            <b-form-group label="Template:" label-for="templateId">
              <b-form-input ref="templateFilterInput" v-model="templateFilter" placeholder="Filter for template names..." autofocus @update="templateId = ''"></b-form-input>
              <b-form-select id="templateId" v-model="templateId" :options="options" :select-size="selectSize">
              </b-form-select>
              <b-form-checkbox class="mt-2" v-model="onlyModality" value="YES" unchecked-value="NO" switch>Show only templates for modality.</b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import broadcast from '../common/broadcast'
import webServices from '../common/webServices'
import permissions from '../common/permissions'

export default {
  name: 'reportCreator',
  components: {
  },
  data() {
    return {
      buttonSize: "sm",
      entryToShow: webServices.getEmptyWorklistEntry(),
      onlyModality: "YES",
      templateList: { 'default': [], 'group': [], 'user': [] },
      templateId: -1,
      templateFilter: '',
      relaunchAfterWarning: false,
      replaceReportId: 0,
      warningMsg: 'Report not created.'
    };
  },
  mounted() {
  },
  computed: {
    okTitle() {
      return (this.replaceReportId != 0) ? "Change Template" : "Create Report"
    },
    options() {
      var allOptions = [
        { value: '', text: 'Please select a template...', disabled: true }
      ]

      var userOptions = {
        label: 'My Templates',
        options: []
      }

      var filteredUser = this.templateList['user'].filter(item => {
        return item['name'].match(new RegExp(this.templateFilter, "i"))
      })
      filteredUser = this.filterByModality(filteredUser)
      if (filteredUser.length == 0) {
        userOptions['options'].push({ value: '', text: 'No user templates', disabled: true })
      }
      for (var i=0; i < filteredUser.length; i++) {
        let t = filteredUser[i]
        userOptions['options'].push({ value: ''+t.id, text: t.name })
      }
      allOptions.push(userOptions)
      // SP-656
      // Only display tenant portion of Keycloak group as customer portion before any / may be incorrect
      // if tenant was updated to be moved to another customer.
      const tenantVisible = permissions.hasPermission(this.entryToShow.group, permissions.CAN_VIEW_TENANT_NAME)
      const tenantName = this.entryToShow.group.replace(/^.*\//, '')
      const customerName = this.$store.state.customers[this.$store.state.customerId].name;

      var groupOptions = {
        label: tenantVisible ? `${customerName}/${tenantName} Templates` : `${customerName} Templates`,
        options: []
      }
      var filteredGroup = this.templateList['group'].filter(item => {
        return item['name'].match(new RegExp(this.templateFilter, "i"))
      })
      filteredGroup = this.filterByModality(filteredGroup)
      if (filteredGroup.length == 0) {
        groupOptions['options'].push({ value: '', text: 'No group templates', disabled: true })
      }
      for (i=0; i < filteredGroup.length; i++) {
        let t = filteredGroup[i]
        groupOptions['options'].push({ value: ''+t.id, text: t.name })
      }
      allOptions.push(groupOptions)

      var defaultOptions = {
        label: 'Default Templates',
        options: []
      }
      var filteredDefault = this.templateList['default'].filter(item => {
        return item['name'].match(new RegExp(this.templateFilter, "i"))
      })
      filteredDefault = this.filterByModality(filteredDefault)
      if (filteredDefault.length == 0) {
        defaultOptions['options'].push({ value: '', text: 'No default templates', disabled: true })
      }
      for (i=0; i < filteredDefault.length; i++) {
        let t = filteredDefault[i]
        defaultOptions['options'].push({ value: ''+t.id, text: t.name })
      }
      allOptions.push(defaultOptions)
      
      return allOptions
    },
    selectSize() {
      return ((this.templateId !== '') || (this.templateFilter === '')) ? 0 : 10
    },
    title() {
      return `${this.okTitle}: ${webServices.getTitleForEntry(this.entryToShow)}`
    }
  },
  methods: {
    show(replaceReportId) {
      // SP-192: make sure user has first and last name defined in profile.
      //
      var userNameDefined = (this.$store.getters.user != '')
      if (!userNameDefined) {
        this.warningMsg = "User's first and last name must be defined in profile to create report. Use User→Profile to update."
        this.relaunchAfterWarning = false
        this.$refs.warningDialog.show()
        return
      }

      const entry = this.$store.getters.worklistEntryForStudy(this.$store.state.activeStudyUid)
      if (entry != null) {
        this.entryToShow = entry
      }
      else {
        this.entryToShow = webServices.getEmptyWorklistEntry()
      }
      this.replaceReportId = replaceReportId
      this.relaunchAfterWarning = false
      this.templateId = ''
      this.templateFilter = ''
      webServices.readReportTemplateList(this.entryToShow.group)
      .then(response => {
        if ((response != null) && (Object.keys(response).length > 0)) {
          this.$log.debug("Read report template list.")
          this.templateList = response['templateList']
        }
        else {
          this.$log.debug("Report template list null or empty.")
        }
      })
      .catch(err => {
        this.$log.error("Error fetching report template list: "+err)
      })
      this.$refs.createDialog.show()
    },
    hide() {
      this.$refs.createDialog.hide()
    },
    filterByModality(templateItems) {
      if (this.onlyModality === 'YES') {
        var modalities = this.entryToShow.modality.trim().split(" ")
        return templateItems.filter(item => {
          for(var i=0; i < modalities.length; i++) {
            if (item['name'].match(new RegExp(modalities[i])) != null) {
              return item['name'].match(new RegExp(modalities[i]))
            }
          }
          return null
        })
      }
      return templateItems
    },
    handleCreateOK() {
      this.$refs.createDialog.hide()
      if (this.templateId === '') {
        this.warningMsg = 'No template selected.'
        this.relaunchAfterWarning = true
        this.$refs.warningDialog.show()
      }
      else
      {
        this.$refs.waitDialog.show()
        const notvalid = '__NoT_VaLiD__'
        var templateSection = notvalid
        var templateName = notvalid
        this.$log.debug("templateId="+this.templateId)
        const sections = Object.keys(this.templateList)
        for (var s=0; s < sections.length; s++)
        {
          for (var i=0; i < this.templateList[sections[s]].length; i++) {
            const t = this.templateList[sections[s]][i]
            if (t.id == this.templateId) {
              templateSection = sections[s]
              templateName = t.name
              break;
            }
          }
          if (templateName !== notvalid) {
            break;
          }
        }
        if (templateName !== notvalid) {
          this.$log.debug(`Creating report for studyUid=${this.studyUid} [${webServices.getTitleForEntry(this.entryToShow)}]...`)
          webServices.createReport(templateSection, templateName, this.entryToShow, this.replaceReportId, 'MT')
          .then(response => {
            this.$refs.waitDialog.hide()
            if ((response != null) && (Object.keys(response).length > 0) && (response.result == 'OK')) {
              this.$log.debug("Report created reportId=["+response.reportId+"].")
              this.$store.commit('changeReportId', response.reportId)
              this.$store.commit('changeActiveComponent', 'ReportEditor')

              // Update worklist entry in all windows...
              //
              setTimeout(() => {
                webServices.readWorklist() // handle if running in worklist window
                broadcast.postMessage(broadcast.REFRESH_WORKLIST_MSG, this.$store.state.uid) // handle if in report window
              }, 1000);
            }
            else {
              this.$log.debug("Unexpected response from report endpoint.")
              this.warningMsg = 'Report not created.'
              this.relaunchAfterWarning = true
              this.$refs.warningDialog.show()
            }
          })
          .catch(err => {
            this.$log.error("Error creating report: "+err)
            this.$refs.waitDialog.hide()
            this.warningMsg = 'Report not created.'
            this.relaunchAfterWarning = true
            this.$refs.warningDialog.show()
          })
        }
        else {
          this.$log.error("Error getting template name")
          this.$refs.waitDialog.hide()
          this.warningMsg = 'Report not created.'
          this.relaunchAfterWarning = true
          this.$refs.warningDialog.show()
        }
      }
    },
    handleWarningOK() {
      if (this.relaunchAfterWarning) {
        this.$refs.createDialog.show(this.origWorklistName, false);
      }
    }
  }
};
</script>
<style scoped>
</style>